import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import "../../styles/style.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";

library.add(fab);

export default function Layout({ children }) {
  useEffect(() => {
    import("bootstrap");
  });

  return (
    <div id="layout">
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00a94e" />
        <meta name="msapplication-TileColor" content="#00a94e" />
      </Helmet>
      <Header />
      <main id="main">{children}</main>
      <Footer />
    </div>
  );
}
